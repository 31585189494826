var timeout = null;
var current = 0;
var slideCount = 0;
var slideDuration = 4000;
var slideTransition = 600;
var inTransition = false;
var nextCallback = false;
var prevCallback = false;
var initialX = 0;
var isDragged = false;
var startingPositions = {
	active: 0,
	prev: -100,
	next: 100
}

$.fn.slider = function (options) {
	var _this = $(this);

	this.inTransition = getTransState;
	this.play = play;
	this.stop = stop;
	this.next = next;
	this.prev = prev;
	this.init = init; 

	init(_this, options);
	return this;
}

function init(_this, options) {
	_this.addClass('slider');

	$.each(_this.children(), function() {
		var staggerDuration = 0;
		$(this).addClass('slide');
		slideCount++;

		$.each($(this).find('.folded'), function() {
			$(this).css({
				'animation-delay': staggerDuration + 's',
				'transition-delay': staggerDuration + 's'
			});
			staggerDuration += 0.3;
		});
	});

	if(_this.children('.active').length === 0) {
		_this.children().first().addClass('active');
	} else {
		current = _this.children('.active').eq();
	}

	if (options.duration) {
		slideDuration = options.duration;
	}

	if(options.arrows) {
		_this
			.append("<div class='arrow next'><i class='fa fa-chevron-right'></i></div>")
			.append("<div class='arrow prev'><i class='fa fa-chevron-left'></i></div>");

		$(document)
			.on("click", ".slider .arrow.next", function(e) {
				e.preventDefault();
				e.stopPropagation();
				next();

				if(options.nextCallback) {
					nextCallback = options.nextCallback;
				}
			})
			.on("click", ".slider .arrow.prev", function(e) {
				e.preventDefault();
				e.stopPropagation();
				prev();
				
				if(options.prevCallback) {
					prevCallback = options.prevCallback;
				}
			});
	}

	$(document)
		.on("mousedown", ".slide", function(evt) {
			if(!inTransition) {
				initialX = evt.clientX;
				isDragged = inTransition = true;
				clearTimeout(timeout);
				
				$(this).mousemove(function(e) {
					var difference = initialX - e.clientX;

					if(isDragged) {
						$(".slide.active").css("left", (difference * -1) + "px");
						var prevSlide = current - 1 < 0 ? (slideCount - 1) : (current - 1) ;
						var nextSlide = current + 1 > slideCount - 1 ? 0 : (current + 1) ;

						
						if((difference * -1) < 0) {
							$(".slide:eq(" + prevSlide + ")").css("left", "100%");
							$(".slide:eq(" + nextSlide + ")").css("left", 0 + "px");
							startingPositions.next = 0;
						} else {
							$(".slide:eq(" + nextSlide + ")").css("left", "100%");
							$(".slide:eq(" + prevSlide + ")").css("left", 0 + "px");	
							startingPositions.prev = 0;	
						}
						
					}
					
				});	
			}
		})
		.on("mouseup", ".slide", function(evt) {
			if(isDragged) {
				var difference = initialX - evt.clientX;
				isDragged = false;
				if(Math.abs(difference) >= ($(this).outerWidth() / 3)) {
					inTransition = false;
					startingPositions.active = (difference * -1);

					if(difference > 0) {
						next();
					} else {
						prev();
					}
				} else {

					startingPositions = {
						active: 0,
						prev: -100,
						next: 100
					};

					$(".slide.active")
						.animate({
							"left": "0"
						}, 300, function() {
							$(".slide:not(.active)").css("left", "100%");
							inTransition = false;
							timeout = setTimeout(function() {
								next();
							}, slideDuration + slideTransition);
						});
				}
			}
		});

	timeout = setTimeout(function() {
		next();
	}, slideDuration + slideTransition);
};

function next() {
	clearTimeout(timeout);

	if(inTransition === false) {
		var nextSlide = current + 1 > slideCount - 1 ? 0 : (current + 1) ;
		inTransition = true;

		$(".slide.active")
			.delay(200)
			.css("left", startingPositions.active)
			.animate({
				"left": "-100%"
			}, 600, function() {
				$(this)
					.removeClass('active')
					.css("transform", "translateX(0)");

				inTransition = false;
			});
		$(".slide:eq(" + nextSlide + ")")
			.css("left", startingPositions.next + "%") 
			.animate({
				"left": "0"
			}, 800, function() {
				$(this)
					.addClass('active')
					.css("transform", "translateX(0)");

				startingPositions = {
					active: 0,
					prev: -100,
					next: 100
				}

				if(nextCallback) {
					nextCallback();
				}

				inTransition = false; 
			});
  
		current = nextSlide;
	}

	timeout = setTimeout(function() {
		next();
	}, slideDuration + slideTransition)
}

function prev() { 
	clearTimeout(timeout);
	if(inTransition === false) {
		var nextSlide = current - 1 < 0 ? (slideCount - 1) : (current - 1) ;
		inTransition = true;

		$(".slide.active")
			.delay(200)
			.css("left", startingPositions.active)
			.animate({
				"left": "100%"
			}, 600, function() {
				$(this).removeClass('active');
				inTransition = false;
			});
		$(".slide:eq(" + nextSlide + ")")
			.css("left", startingPositions.prev + "%")
			.animate({
				"left": "0"
			}, 800, function() {
				$(this).addClass('active');

				startingPositions = {
					active: 0,
					prev: -100,
					next: 100
				}

				if(prevCallback) {
					prevCallback();
				}

				inTransition = false;
			});

		current = nextSlide;
	}

	timeout = setTimeout(function() {
		next();
	}, slideDuration + slideTransition)
}

function stop() {
	if(inTransition === false) {
		clearTimeout(timeout);
	}
}

function play() {
	if(inTransition === false) {
		timeout = setTimeout(function() {
			next();
		}, slideDuration + slideTransition)
	}
}

function getTransState() {
	return inTransition;
}